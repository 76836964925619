$(".js-search-btn").on('click', function(event) {
	$(".js-search").addClass("is-active");
});

$(".js-search-close").on('click', function(event) {
	$(".js-search").removeClass("is-active");
});

var percentage = 85;
var dot_height = $(".js-news-name").height() * (percentage / 100);

$(".js-news-name").textTailor({
	fit: false,
	lineHeight: 1.5,
});

$(".js-news-name-big").textTailor({
	fit: false,
	lineHeight: 1.5,
});

$(".js-cert").slick({
	slidesToShow: 4,
	slidesToScroll: 4,
	infinite: false,
	arrows: true,
	dots: true, 
	prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" style="">\
		<svg width="16" height="17" viewBox="0 0 16 17" class="svg-icon">\
			<use xlink:href="#svg-arrow-slider"></use>\
		</svg>\
	</button>',
    nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button" style="">\
		<svg width="16" height="17" viewBox="0 0 16 17" class="svg-icon">\
			<use xlink:href="#svg-arrow-slider"></use>\
		</svg>\
	</button>',
	responsive: [
	  {
		breakpoint: 399,
		settings: {
		  slidesToShow: 1,
		  slidesToScroll: 1,
		}
	  },{
		breakpoint: 750,
		settings: {
		  slidesToShow: 2,
		  slidesToScroll: 2,
		}
	  },{
		breakpoint: 970,
		settings: {
		  slidesToShow: 3,
		  slidesToScroll: 3,
		}
	  }]

});

$(".js-gallery").slick({
	slidesToShow: 3,
	slidesToScroll: 1,
	infinite: false,
	arrows: true,
	prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" style="">\
		<svg width="16" height="17" viewBox="0 0 16 17" class="svg-icon">\
			<use xlink:href="#svg-arrow-slider"></use>\
		</svg>\
	</button>',
    nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button" style="">\
		<svg width="16" height="17" viewBox="0 0 16 17" class="svg-icon">\
			<use xlink:href="#svg-arrow-slider"></use>\
		</svg>\
	</button>',

	responsive: [
	  {
		breakpoint: 399,
		settings: {
		  slidesToShow: 1,
		}
	  },{
		breakpoint: 970,
		settings: {
		  slidesToShow: 2,
		}
	  }]
});

$(".js-gallery-branches").slick({
	slidesToShow: 3,
	slidesToScroll: 1,
	arrows: true,
	infinite: false,
	prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" style="">\
		<svg width="16" height="17" viewBox="0 0 16 17" class="svg-icon">\
			<use xlink:href="#svg-arrow-slider"></use>\
		</svg>\
	</button>',
    nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button" style="">\
		<svg width="16" height="17" viewBox="0 0 16 17" class="svg-icon">\
			<use xlink:href="#svg-arrow-slider"></use>\
		</svg>\
	</button>',

	responsive: [
	  {
		breakpoint: 399,
		settings: {
		  slidesToShow: 1,
		}
	  },{
		breakpoint: 750,
		settings: {
		  slidesToShow: 2,
		}
	  }]
});

$(".js-doctor-slider").slick({
	slidesToShow: 3,
	slidesToScroll: 1,
	arrows: true,
	prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" style="">\
		<svg width="16" height="17" viewBox="0 0 16 17" class="svg-icon">\
			<use xlink:href="#svg-arrow-slider"></use>\
		</svg>\
	</button>',
    nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button" style="">\
		<svg width="16" height="17" viewBox="0 0 16 17" class="svg-icon">\
			<use xlink:href="#svg-arrow-slider"></use>\
		</svg>\
	</button>',

	responsive: [
	  {
		breakpoint: 399,
		settings: {
		  slidesToShow: 1,
		}
	  },{
		breakpoint: 750,
		settings: {
		  slidesToShow: 2,
		}
	  }]
});

$(".js-img-slider").slick({
	slidesToShow: 1,
	slidesToScroll: 1,
	arrows: true,
	prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" style="">\
		<svg width="16" height="17" viewBox="0 0 16 17" class="svg-icon">\
			<use xlink:href="#svg-arrow-slider"></use>\
		</svg>\
	</button>',
    nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button" style="">\
		<svg width="16" height="17" viewBox="0 0 16 17" class="svg-icon">\
			<use xlink:href="#svg-arrow-slider"></use>\
		</svg>\
	</button>'

});

$(".js-select").styler();

$(".js-phone").mask("+7(999) 999-9999");

$(".js-tab").on('click', function(e){
    let tabNumber = $(this).data("tab");

    $(".js-tab").removeClass("is-active");
    $(".js-tab-block").fadeOut(300, function(){});
    $(".js-tab-block[data-tab='"+tabNumber+"']").fadeIn(300, function(){});
    $(".js-tab[data-tab='"+tabNumber+"']").addClass("is-active");
});

$(".js-popup-link").on('click', function(e){
	let popupName = $(this).data("popup");

	$(".js-popup[data-popup="+popupName+"]").addClass("is-open");
});

$(".js-popup-close").on('click', function(e){
	$(this).parents(".js-popup").removeClass("is-open");
});

$('.js-popup').on('click', function(e) {
	if (e.target !== this)
	  return;
	
	$(this).removeClass("is-open");
});

$(".js-hamburger").on('click', function(e) {
	$(".js-menu").toggleClass("is-active");
});

$(".js-menu-control").on('click', function(e) {
	let menu = $(this).parents(".js-menu-item").find(".js-menu-dropdown");

	$(".js-menu-dropdown").removeClass("is-active");
	$(menu).addClass("is-active");
});


$(".js-menu-close").on('click', function(e) {
	$(".js-menu").toggleClass("is-active");

	$(".js-menu-dropdown").removeClass("is-active");
});

$(".js-map-icon").on('mouseenter', function(e) {
	let city = $(this).data("city");

	$(".main-map__link--"+city+"").addClass("is-active");
	$(this).addClass("is-active");
});

$(".js-map-icon").on('mouseleave', function(e) {
	let city = $(this).data("city");

	$(".main-map__link--"+city+"").removeClass("is-active");
	$(this).removeClass("is-active");
});


$(".js-map-link").on('mouseenter', function(e) {
	let city = $(this).data("city");

	$(".main-map__icon--"+city+"").addClass("is-active");
	$(this).addClass("is-active");
});

$(".js-map-link").on('mouseleave', function(e) {
	let city = $(this).data("city");

	$(".main-map__icon--"+city+"").removeClass("is-active");
	$(this).removeClass("is-active");
});







$(".js-vacancy-more").on('click', function(e) {
	let parents = $(this).parents(".vacancy__item");

	$(this).toggleClass("is-active");

	if ($(this).hasClass("is-active")) {
		$(this).find(".js-text").text('свернуть');
	} else {
		$(this).find(".js-text").text('развернуть');
	}
	
	$(parents).find(".js-vacancy").toggleClass("is-active");
});

if ($('#map').length) {
    var init = function init() {
        var myMap = new ymaps.Map("map", {
            center: [47.227598, 39.751772],
            zoom: 15
        });
		myMap.controls.add(new ymaps.control.ZoomControl());

        var myPlacemark = new ymaps.Placemark(myMap.getCenter(), {}, {});
        myMap.geoObjects.add(myPlacemark);
    };

    ymaps.ready(init);
}